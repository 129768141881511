export interface AppUserIndex {
  id: string;
  email: string;
  content: {
    name: string;
    surname: string;
  };
  is_verified: boolean;
  created_at: string;
  updated_at: string;
}

export class AppUserIndex implements AppUserIndex {
  id: string;
  email: string;
  content: {
    name: string;
    surname: string;
  };
  is_verified: boolean;
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = "";
    this.email = "";
    this.content = {
      name: "",
      surname: "",
    };
    this.is_verified = false;
    this.created_at = "";
    this.updated_at = "";
  }
}