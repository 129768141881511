import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// Models
import { AppOrganizationMemberIndex } from "@/models/app/organization-member";
import { AuthenticationLoginResponse} from "@/models/authentication/login/response";

// const ls = new SecureLS({
//   isCompression: false
// });

export default createStore({
  plugins: [
    createPersistedState({
      key: "BIZZZ",
      // storage: {
      //   // getItem: (key) => ls.get(key),
      //   // setItem: (key, value) => ls.set(key, value),
      //   // removeItem: (key) => ls.remove(key)
      // }
    }),
  ],
  state: {
    authentication: new AuthenticationLoginResponse(),
    organizationMember: new AppOrganizationMemberIndex(),
    organizationMembership: [],
    locality: "hr",
  },
  getters: {
    isAuthenticated(state): boolean {
      return state.authentication.token !== "";
    },
    getAuthentication(state): any {
      return state.authentication;
    },
    getOrganizationMember(state): any {
      return state.organizationMember;
    },
    getOrganizationMembership(state): Array<any> {
      return state.organizationMembership;
    },
    getLocality(state): string {
      return state.locality;
    }
  },
  mutations: {
    setAuthentication(state: any, data: AuthenticationLoginResponse): void {
      state.authentication = data;
    },
    setOrganizationMember(state: any, data: AppOrganizationMemberIndex): void {
      state.organizationMember = data;
    },
    setOrganizationMembership(state: any, data: Array<AppOrganizationMemberIndex>): void {
      state.organizationMembership = data;
    },
    setLocality(state: any, data: string): void {
      state.locality = data;
    },
    setLogout(state: any): void {
      state.authentication = new AuthenticationLoginResponse();
      state.organizationMember = new AppOrganizationMemberIndex();
      state.organizationMembership = [];
      state.locality = "hr";
    },
  },
  actions: {
    setAuthentication({ commit }, data: AuthenticationLoginResponse): void {
      commit("setAuthentication", data);
    },
    setOrganizationMember({ commit }, data: AppOrganizationMemberIndex): void {
      commit("setOrganizationMember", data);
    },
    setOrganizationMembership({ commit }, data: Array<AppOrganizationMemberIndex>): void {
      commit("setOrganizationMembership", data);
    },
    setLocality({ commit }, data: string): void {
      commit("setLocality", data);
    },
    setLogout({ commit }): void {
      commit("setLogout");
    },
  },
});