import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691d0fe7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticationLoginIndexVue = _resolveComponent("AuthenticationLoginIndexVue")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthenticationLoginIndexVue),
    _createElementVNode("footer", null, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex",
            align: "middle"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, { to: { name: 'TermsOfUseIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.termsOfUse), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, { to: { name: 'PrivacyPolicyIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.privacyPolicy), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translation.allRightsReserved) + " © " + _toDisplayString(_ctx.getYear), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}