// Vendors
import moment, {Moment} from "moment";

const getDateFormat = (date: string): string => {
  if (moment(date).format("DD.MM.YYYY.") === "Invalid date") {
    return date;
  }

  return moment(date).format("DD.MM.YYYY.");
};

const getDateTimeFormat = (date: string): string => {
  return moment(date).format("DD.MM.YYYY. - HH:mm");
};

const getDateYearFormat = (date: string): string => {
  return moment(date).format("YYYY");
};

const getToday = (): string => {
  return moment().format("DD.MM.YYYY.");
};

const getYear = (): string => {
  return moment().format("YYYY");
};

const getMonthFirstDay = (): string => {
  return moment().startOf("month").toISOString();
};

const getMonthLastDay = (): string => {
  return moment().endOf("month").toISOString();
};

const getYearFirstDay = (): string => {
  return moment().startOf("year").toISOString();
};

const getYearLastDay = (): string => {
  return moment().endOf("year").toISOString();
};

const getDateStartTime = (date: string): string => {
  return moment(date).startOf("day").toISOString();
};

const getDateEndTime = (date: string): string => {
  return moment(date).endOf("day").toISOString();
};


export {
  getDateFormat,
  getDateTimeFormat,
  getDateYearFormat,
  getToday,
  getYear,
  getMonthFirstDay,
  getMonthLastDay,
  getYearFirstDay,
  getYearLastDay,
  getDateStartTime,
  getDateEndTime,
};
