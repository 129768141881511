const translations = [
  {
    "aboutApplication": {
      "hr": "O aplikaciji",
      "en": "About Application",
    },
    "aboutApplicationDescription": {
      "hr": "<p>Racuncheck nova je web i mobilna aplikacija iz paketa We Run koja nudi mogućnost upravljanja nogometnim klubom.</p><p>Prezicno smo monitorirali razne organizacije i došli do najboljeg rješenja za upravljanjem nogometnog kluba ili manje organizacije kako bi pomogli u razvoju mladih igračica i igrača za pristupačnu cijenu.</p><p>Za više informacija, obratite nam se bez zadrške na info@codicode.hr</p>",
      "en": "<p>Racuncheck is a new web and mobile application from the We Run package that offers the possibility of managing a football club.</p><p>We have closely monitored various organizations and come up with the best solution for managing a football club or smaller organization to help in the development of young players for an affordable price.</p><p>For more information, contact us without hesitation on info@codicode.hr</p>",
    },
    "accounts": {
      "hr": "Računi",
      "en": "Accounts",
    },
    "accountNumber": {
      "hr": "Broj računa",
      "en": "Account number",
    },
    "action": {
      "hr": "Akcija",
      "en": "Aktion",
    },
    "add": {
      "hr": "Dodajte",
      "en": "Add",
    },
    "address": {
      "hr": "Adresa",
      "en": "Address",
    },
    "addressPlaceholder": {
      "hr": "npr. Ljudevita Gaja 40",
      "en": "e.g. Downing street 40",
    },
    "administrator": {
      "hr": "Administrator",
      "en": "Administrator",
    },
    "administrators": {
      "hr": "Administratori",
      "en": "Administrators",
    },
    "amount": {
      "hr": "Iznos",
      "en": "Amount",
    },
    "amountWithVAT": {
      "hr": "Iznos (sa PDV)",
      "en": "Amount (with VAT)",
    },
    "amountWithoutVAT": {
      "hr": "Iznos (bez PDV)",
      "en": "Amount (without VAT)",
    },
    "amountOfPayment": {
      "hr": "Iznos uplate",
      "en": "Amount of Payment",
    },
    "allPartners": {
      "hr": "Svi partneri",
      "en": "All partners",
    },
    "allPlaceOfCosts": {
      "hr": "Sva mjesta troškova",
      "en": "All place of costs",
    },
    "allStatuses": {
      "hr": "Svi statusi",
      "en": "All statuses",
    },
    "allPayments": {
      "hr": "Sve uplate",
      "en": "All payments",
    },
    "allRightsReserved": {
      "hr": "Sva prava pridržana",
      "en": "All rights reserved",
    },
    "areYouSure": {
      "hr": "Jeste li sigurni?",
      "en": "Are you sure?",
    },
    "article": {
      "hr": "Artikl",
      "en": "Article",
    },
    "articles": {
      "hr": "Artikli",
      "en": "Articles",
    },
    "bank": {
      "hr": "Banka",
      "en": "Bank",
    },
    "banks": {
      "hr": "Banke",
      "en": "Banks",
    },
    "balance": {
      "hr": "Stanje",
      "en": "Balance",
    },
    "cancel": {
      "hr": "Odustanite",
      "en": "Cancel",
    },
    "changePassword": {
      "hr": "Promjenite lozinku",
      "en": "Change password",
    },
    "change": {
      "hr": "Promjena",
      "en": "Change",
    },
    "choose": {
      "hr": "Odaberite",
      "en": "Choose",
    },
    "closed": {
      "hr": "Zatvoren",
      "en": "Closed",
    },
    "closedShort": {
      "hr": "Z",
      "en": "C",
    },
    "contact": {
      "hr": "Kontakt",
      "en": "Contact",
    },
    "continue": {
      "hr": "Nastavite",
      "en": "Continue",
    },
    "compensation": {
      "hr": "Kompenzacija",
      "en": "Compensation",
    },
    "compensations": {
      "hr": "Kompenzacije",
      "en": "Compensations",
    },
    "country": {
      "hr": "Država",
      "en": "Country",
    },
    "countryPlaceholder": {
      "hr": "npr. Hrvatska",
      "en": "e.g. Great Britain",
    },
    "county": {
      "hr": "Županija",
      "en": "County",
    },
    "counties": {
      "hr": "Županije",
      "en": "Counties",
    },
    "createOrganization": {
      "hr": "Napravite organizaciju",
      "en": "Create organization",
    },
    "currentBalance": {
      "hr": "Trenutno stanje",
      "en": "Current balance",
    },
    "dashboard": {
      "hr": "Radna ploča",
      "en": "Dashboard",
    },
    "date": {
      "hr": "Datum",
      "en": "Date",
    },
    "dateOfClosing": {
      "hr": "Datum zatvaranja",
      "en": "Date of Closing",
    },
    "dateOfExchangeRate": {
      "hr": "Datum valute",
      "en": "Date of Exchange",
    },
    "dateOfExchangeRateShort": {
      "hr": "Valuta",
      "en": "Exchange",
    },
    "details": {
      "hr": "Detalji",
      "en": "Details",
    },
    "difference": {
      "hr": "Razlika",
      "en": "Difference",
    },
    "dragAndDropXMLFile": {
      "hr": "Dovucite i prenesite XML datoteku...",
      "en": "Drag and drop XML file...",
    },
    "edit": {
      "hr": "Uredite",
      "en": "Edit",
    },
    "email": {
      "hr": "E-mail",
      "en": "E-mail",
    },
    "emailPlaceholder": {
      "hr": "npr. ivan.horvat@mail.com",
      "en": "e.g. john.doe@mail.com",
    },
    "excerpt": {
      "hr": "Izvadak",
      "en": "Excerpt",
    },
    "excerpts": {
      "hr": "Izvatci",
      "en": "Excerpts",
    },
    "filter": {
      "hr": "Filter",
      "en": "Filter",
    },
    "forgottenPassword": {
      "hr": "Zaboravljena lozinka?",
      "en": "Forgotten password?",
    },
    "giroAccount": {
      "hr": "Žiro račun",
      "en": "Giro Account",
    },
    "identificationNumber": {
      "hr": "Identifikacijski broj",
      "en": "Identification Number",
    },
    "identificationNumberPlaceholder": {
      "hr": "npr. 11223344",
      "en": "e.g. 11223344",
    },
    "incomingCompensation": {
      "hr": "Ulazna kompenzacija",
      "en": "Incoming compensation",
    },
    "incomingInvoice": {
      "hr": "Ulazni račun",
      "en": "Incoming Invoice",
    },
    "incomingInvoices": {
      "hr": "Ulazni računi",
      "en": "Incoming Invoices",
    },
    "incomingInvoiceHasBeenClosed": {
      "hr": "Ulazni račun je zatvoren",
      "en": "Incoming invoice has been closed",
    },
    "incoming": {
      "hr": "Ulazne",
      "en": "Incoming",
    },
    "invoice": {
      "hr": "Račun",
      "en": "Invoice",
    },
    "invoices": {
      "hr": "Računi",
      "en": "Invoices",
    },
    "invoiceTypes": {
      "hr": "Vrste računa",
      "en": "Invoice Types",
    },
    "impressum": {
      "hr": "Impressum",
      "en": "Impressum",
    },
    "impressumContent": {
      "hr": "<p><strong>Verzija:</strong> 2.0.0</p>\n" +
        "    <p><strong>Izdavač:</strong></p>\n" +
        "    <p>CODICODE d.o.o. <br> Ljudevita Gaja 40,<br>10290 Zaprešić,<br>Hrvatska<br></p>\n" +
        "    <p>OIB: 99486125848</p>\n" +
        "    <p><strong>Informacije i podrška:</strong></p>\n" +
        "    <p>info@codicode.hr</p>",
      "en": "<p><strong>Verzija:</strong> 2.0.0</p>\n" +
        "    <p><strong>Publisher:</strong></p>\n" +
        "    <p>CODICODE d.o.o. <br> Ljudevita Gaja 40,<br>10290 Zaprešić,<br>Croatia</p>\n" +
        "    <p>VAT: 99486125848</p>\n" +
        "    <p><strong>Information and support:</strong></p>\n" +
        "    <p>info@codicode.hr</p>",
    },
    "information": {
      "hr": "Informacije",
      "en": "Information",
    },
    "initialBalance": {
      "hr": "Inicijalno stanje",
      "en": "Initial balance",
    },
    "invoiceNumber": {
      "hr": "Broj računa",
      "en": "Invoice Number",
    },
    "invoiceNumberInYear": {
      "hr": "Broj računa u godini",
      "en": "Invoice Number in Year",
    },
    "invoiceNumberInYearURA": {
      "hr": "Broj URA",
      "en": "Number URA",
    },
    "invoiceNumberInYearIRA": {
      "hr": "Broj IRA",
      "en": "Number IRA",
    },
    "invoiceNumberInYearIRAURA": {
      "hr": "IRA/URA",
      "en": "IRA/URA",
    },
    "invoicePayment": {
      "hr": "Uplata računa",
      "en": "Invoice Payment",
    },
    "invoicePayments": {
      "hr": "Uplate računa",
      "en": "Invoice Payments",
    },
    "invoiceType": {
      "hr": "Vrsta računa",
      "en": "Invoice Type",
    },
    "leadingNumber": {
      "hr": "Vodeći broj",
      "en": "Leading Number",
    },
    "leadingNumberPlaceholder": {
      "hr": "npr. 12236",
      "en": "e.g. 12236",
    },
    "listOfItemsStatus": {
      "hr": "Stavke računa",
      "en": "Invoice Items",
    },
    "login": {
      "hr": "Prijava",
      "en": "Login",
    },
    "logout": {
      "hr": "Odjava",
      "en": "Logout",
    },
    "masterData": {
      "hr": "Matični podaci",
      "en": "Master data",
    },
    "menu": {
      "hr": "Izbornik",
      "en": "Menu",
    },
    "model": {
      "hr": "Model",
      "en": "Model",
    },
    "multiple": {
      "hr": "Višestruka",
      "en": "Multiple",
    },
    "name": {
      "hr": "Ime",
      "en": "Name",
    },
    "namePlaceholder": {
      "hr": "npr. Ivan",
      "en": "e.g. John",
    },
    "no": {
      "hr": "Ne",
      "en": "No",
    },
    "noData": {
      "hr": "Nema podataka...",
      "en": "No data...",
    },
    "of": {
      "hr": "od",
      "en": "of",
    },
    "opened": {
      "hr": "Otvoren",
      "en": "Opened",
    },
    "openedShort": {
      "hr": "O",
      "en": "O",
    },
    "organizations": {
      "hr": "Organizacije",
      "en": "Organizations",
    },
    "organizationMembers": {
      "hr": "Članovi organizacije",
      "en": "Organization Members",
    },
    "ordinalNumber": {
      "hr": "Redni broj",
      "en": "Ordinal Number",
    },
    "ordinalNumberPlaceholder": {
      "hr": "npr. 1",
      "en": "e.g. 1",
    },
    "outgoingCompensation": {
      "hr": "Izlazna kompenzacija",
      "en": "Outgoing compensation",
    },
    "outgoingInvoice": {
      "hr": "Izlazni račun",
      "en": "Outgoing Invoice",
    },
    "outgoingInvoices": {
      "hr": "Izlazni računi",
      "en": "Outgoing Invoices",
    },
    "outgoingInvoiceHasBeenClosed": {
      "hr": "Izlazni račun je zatvoren",
      "en": "Outgoing invoice has been closed",
    },
    "outgoing": {
      "hr": "Izlazne",
      "en": "Outgoing",
    },
    "page": {
      "hr": "Stranica",
      "en": "Page",
    },
    "paid": {
      "hr": "Uplaćeno",
      "en": "Paid",
    },
    "paidAmount": {
      "hr": "Uplaćeni iznos",
      "en": "Paid Amount",
    },
    "paidEstimateExit": {
      "hr": "Uplaćeni izlazni predračuni",
      "en": "Paid Estimate Exit",
    },
    "paidEstimateExitPlaceholder": {
      "hr": "npr. 1000,00",
      "en": "e.g. 1000,00",
    },
    "paidEstimateEntry": {
      "hr": "Uplaćeni ulazni predračuni",
      "en": "Paid Estimate Entry",
    },
    "paidEstimateEntryPlaceholder": {
      "hr": "npr. 1000,00",
      "en": "e.g. 1000,00",
    },
    "paymentDescription": {
      "hr": "Opis plaćanja",
      "en": "Payment Description",
    },
    "partner": {
      "hr": "Partner",
      "en": "Partner",
    },
    "partners": {
      "hr": "Partneri",
      "en": "Partners",
    },
    "pay": {
      "hr": "Uplatite",
      "en": "Pay",
    },
    "payAndContinue": {
      "hr": "Uplatite i nastavite",
      "en": "Pay and continue",
    },
    "paymentPurpose": {
      "hr": "Svrha plaćanja",
      "en": "Payment Purpose",
    },
    "paymentType": {
      "hr": "Vrsta plaćanja",
      "en": "Payment Type",
    },
    "paymentTypes": {
      "hr": "Vrste plaćanja",
      "en": "Payment Types",
    },
    "payments": {
      "hr": "Uplate",
      "en": "Payments",
    },
    "password": {
      "hr": "Lozinka",
      "en": "Password",
    },
    "passwordPlaceholder": {
      "hr": "********",
      "en": "********",
    },
    "passwordRecovery": {
      "hr": "Obnova lozinke",
      "en": "Password recovery",
    },
    "period": {
      "hr": "Razdoblje",
      "en": "Period",
    },
    "phone": {
      "hr": "Telefon",
      "en": "Phone",
    },
    "phonePlaceholder": {
      "hr": "npr. +385 99 444 5555",
      "en": "e.g. +385 99 444 5555",
    },
    "place": {
      "hr": "Mjesto",
      "en": "Place",
    },
    "placePlaceholder": {
      "hr": "npr. Zagreb",
      "en": "e.g. Zagreb",
    },
    "placeOfCost": {
      "hr": "Mjesto troška",
      "en": "Place of Cost",
    },
    "placeOfCosts": {
      "hr": "Mjesto troškova",
      "en": "Place of Costs",
    },
    "places": {
      "hr": "Mjesta",
      "en": "Places",
    },
    "postCode": {
      "hr": "Poštanski broj",
      "en": "Post code",
    },
    "postCodePlaceholder": {
      "hr": "npr. 10000",
      "en": "e.g. 10000",
    },
    "privacyPolicy": {
      "hr": "Politika privatnosti",
      "en": "Privacy Policy",
    },
    "privacyPolicyContent": {
      "hr": "<p><strong>Izjava o zaštiti i prikljupljanju osobnih podataka i njihovom korištenju</strong></p>\n" +
        "    <p>Nama je privatnost posjetitelja i korisnika posebno važna. Sve osobne informacije koje ostavite na našoj stranici su sigurno pohranjene, a skupljamo samo podatke koje dopušta zakon o zaštiti osobnih podataka i zakon o elektronskim komunikacijama.</p>\n" +
        "    <p>CODICODE d.o.o. se obavezuje pružati zaštitu osobnim podacima korisnika, na način da prikuplja samo nužne, osnovne podatke o korisnicima koji su nužni za ispunjenje naših obveza; informira korisnike o načinu korištenja prikupljenih podataka, redovito daje korisnicima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje. Svi se podaci o korisnicima strogo čuvaju i dostupni su samo djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi djelatnici CODICODE d.o.o. i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.</p>\n" +
        "    <p><strong>Vrsta osobnih podataka koji se prikupljaju preko mrežne stranice</strong></p>\n" +
        "    <p>CODICODE d.o.o. može prikupljati sljedeće osobne podatke korisnika mrežne stranice preko odgovarajućih obrazaca: ime, adresa e-pošte, poštanska adresa, telefonski broj, datum rođenja, spol kada korisnik posjeti mrežnu stranicu, registrira se, kontaktira podršku ili sudjeluje u promocijama ili anketama poduzeća CODICODE d.o.o.. Spomenute se informacije ni u kojem slučaju neće prikupljati preko kanala koji nisu prethodno navedeni, čak ni kada su oni dostupni na internetu (e-pošta,  razgovor, Facebook itd.). Ako se spomenute informacije prime preko jednog od navedenih načina, CODICODE d.o.o. se njima neće koristiti ni u koju svrhu navedenu u sljedećem odjeljku. CODICODE d.o.o. k tomu neće snositi odgovornost za sigurnost spomenutih podataka niti može jamčiti njihovu povjerljivost.</p>\n" +
        "    <p>U svim su slučajevima podaci zahtijevani preko mrežne stranice potrebni radi pružanja optimalne usluge korisniku. U slučaju kada korisnik ne pruži zahtijevane podatke, CODICODE d.o.o. ne može jamčiti da će pružene informacije i usluge u potpunosti zadovoljiti korisnikove potrebe.</p>\n" +
        "    <p><strong>Način na koji se CODICODE d.o.o. koristi korisnikovim osobnim podacima</strong></p>\n" +
        "    <p>CODICODE d.o.o. obrađivati korisničke podatke u sljedeće svrhe Slanje komercijalnih informacija e-poštom kako bi ih se izvijestilo o novim proizvodima, posebnim ponudama i novostima u vezi s mrežnom stranicom i/ili poduzećem CODICODE d.o.o. općenito, newsletterima. Spomenute komercijalne informacije odnose se na proizvode ili usluge koje nudi CODICODE d.o.o. kao i svi suradnici ili partneri s kojima je poduzeće sklopilo ugovor o prodajnoj promociji među svojim kupcima. Suradnici ili partneri ni u kojem slučaju neće imati pristup osobnim podacima korisnika. U svim će slučajevima komercijalne obavijesti upućivati CODICODE d.o.o. te će se one odnositi na proizvode i usluge unutar poslovnog sektora poduzeća. Korisnici mogu otkazati pružanje svih pretplaćenih usluga klikom na odjeljak Pretplate</p>\n" +
        "    <p><strong>Način na koji CODICODE d.o.o. koristi moje osobne podatke</strong></p>\n" +
        "    <p>Mrežna stranica koristi se tehnologijama za zaštitu podataka kao što su vatrozid, postupci za provjeru pristupa i kriptografski mehanizmi radi sprječavanja svih oblika neovlaštenog pristupa podacima i jamčenja povjerljivosti podataka. Radi postizanja spomenutih ciljeva, korisnici prihvaćaju da će CODICODE d.o.o. prikupljati podatke u svrhu provjere kontrola pristupa.</p>\n" +
        "    <p>Poduzeće CODICODE d.o.o. izjavljuje da je usvojilo sve tehničke i organizacijske mjere potrebne za jamčenje sigurnosti i cjelovitosti osobnih podataka koje obrađuje te za sprječavanje njihova gubitka, izmjenjivanja i/ili neovlaštenog pristupa preko trećih strana.</p>\n" +
        "    <p><strong>Dijeli li CODICODE d.o.o. moje osobne podatke?</strong></p>\n" +
        "    <p>CODICODE d.o.o. izričito obavještava i jamči korisnicima da se njihovi osobni podaci ni u kojem slučaju neće prosljeđivati trećim stranama i da će prije prosljeđivanja osobnih podataka zahtijevati izričiti, informirani i jasni pristanak njihovih vlasnika.</p>\n" +
        "    <p><strong>Moja prava u pogledu upravljanja mojim osobnim podacima</strong></p>\n" +
        "    <p>Poduzeće CODICODE d.o.o. korisnicima u svakom trenutku jamči mogućnost iskorištavanja njihova prava na pristup, ispravljanje, poništavanje ili protivljenje osobnim podacima koje su pružili pod uvjetima sadržanima u primjenjivom zakonodavstvu. Korisnici mrežne stranice mogu iskoristiti spomenuta prava slanjem pisanog zahtjeva, uz prilaganje kopije njihove nacionalne identifikacijske isprave, na adresu info@codicode.hr</p>",
      "en": "<p><strong>Statement on the protection and connection of personal data and their use</strong></p>\n" +
        "    <p>The privacy of visitors and users is particularly important to us. All personal information you leave on our site is securely stored, and we only collect data that is permitted by the law on personal data protection and the law on electronic communications.</p>\n" +
        "    <p>CODICODE d.o.o. undertakes to provide protection to users' personal data, in such a way that it collects only necessary, basic data about users that are necessary for the fulfillment of our obligations; informs users about the way the collected data is used, regularly gives users the possibility to choose about the use of their data, including the possibility to decide whether or not they want their name to be removed from the lists used for marketing campaigns. All user data is strictly kept and is available only to employees who need this data to perform their work. All employees of CODICODE d.o.o. and business partners are responsible for respecting the principles of privacy protection.</p>\n" +
        "    <p><strong>Type of personal data collected through the website</strong></p>\n" +
        "    <p>CODICODE d.o.o. may collect the following personal data of the website user through appropriate forms: name, e-mail address, postal address, telephone number, date of birth, gender when the user visits the website, registers, contacts support or participates in CODICODE d.o.o. promotions or surveys. In no case will the mentioned information be collected through channels that are not previously mentioned, even when they are available on the Internet (email, chat, Facebook, etc.). If the mentioned information is received through one of the mentioned methods, CODICODE d.o.o. they will not be used for any purpose specified in the following section. CODICODE d.o.o. in addition, it will not bear responsibility for the security of the mentioned data, nor can it guarantee their confidentiality.</p>\n" +
        "    <p>In all cases, the data requested via the website is necessary to provide optimal service to the user. In case the user does not provide the required data, CODICODE d.o.o. cannot guarantee that the information and services provided will fully meet the user's needs.</p>\n" +
        "    <p><strong>The way in which CODICODE d.o.o. uses the user's personal data</strong></p>\n" +
        "    <p>CODICODE d.o.o. process user data for the following purposes Sending commercial information by e-mail to inform them about new products, special offers and news related to the website and/or the company CODICODE d.o.o. in general, newsletters. The mentioned commercial information refers to products or services offered by CODICODE d.o.o. as well as all associates or partners with whom the company has concluded a sales promotion contract among its customers. Associates or partners will not have access to the user's personal data under any circumstances. In all cases, commercial notifications will be sent by CODICODE d.o.o. and they will refer to products and services within the company's business sector. Users can cancel the provision of all subscription services by clicking on the Subscriptions</p>\n" +
        "    <p><strong>The way in which CODICODE d.o.o. uses my personal data</strong></p>\n" +
        "    <p>The website uses data protection technologies such as firewalls, access control procedures and cryptographic mechanisms to prevent all forms of unauthorized access to data and to guarantee data confidentiality. In order to achieve the aforementioned goals, users accept that CODICODE d.o.o. will collect data for the purpose of checking access controls.</p>\n" +
        "    <p>The company CODICODE d.o.o. declares that it has adopted all technical and organizational measures necessary to guarantee the security and integrity of the personal data it processes and to prevent their loss, alteration and/or unauthorized access by third parties.</p>\n" +
        "    <p><strong>Is it shared by CODICODE d.o.o. my personal data?</strong></p>\n" +
        "    <p>CODICODE d.o.o. izričito obavještava i jamči korisnicima da se njihovi osobni podaci ni u kojem slučaju neće prosljeđivati trećim stranama i da će prije prosljeđivanja osobnih podataka zahtijevati izričiti, informirani i jasni pristanak njihovih vlasnika.</p>\n" +
        "    <p><strong>My rights regarding the management of my personal data</strong></p>\n" +
        "    <p>The company CODICODE d.o.o. guarantees users at any time the possibility of exercising their right to access, correct, cancel or oppose the personal data they have provided under the conditions contained in the applicable legislation. Users of the website can exercise the aforementioned rights by sending a written request, along with a copy of their national identification document, to the address info@codicode.hr</p>",
    },
    "referenceNumber": {
      "hr": "Poziv na broj",
      "en": "Reference Number",
    },
    "remaining": {
      "hr": "Preostalo",
      "en": "Remaining",
    },
    "remove": {
      "hr": "Uklonite",
      "en": "Remove",
    },
    "report": {
      "hr": "Izvještaj",
      "en": "Report",
    },
    "reports": {
      "hr": "Izvještaji",
      "en": "Reports",
    },
    "return": {
      "hr": "Povratak",
      "en": "Return",
    },
    "review": {
      "hr": "Pregled",
      "en": "Review",
    },
    "reviewExcerpt": {
      "hr": "Pregled izvoda",
      "en": "Review Excerpt",
    },
    "role": {
      "hr": "Uloga",
      "en": "Role",
    },
    "save": {
      "hr": "Spremite",
      "en": "Save",
    },
    "search": {
      "hr": "Pretražite...",
      "en": "Search...",
    },
    "searchUnitOfMeasures": {
      "hr": "Pretražite jedinice količine...",
      "en": "Search Unit of Measures...",
    },
    "settings": {
      "hr": "Postavke",
      "en": "Settings",
    },
    "sort": {
      "hr": "Sortiranje",
      "en": "Sort",
    },
    "spent": {
      "hr": "Potrošeno",
      "en": "Spent",
    },
    "status": {
      "hr": "Status",
      "en": "Status",
    },
    "standard": {
      "hr": "Standardna",
      "en": "Standard",
    },
    "surname": {
      "hr": "Prezime",
      "en": "Surname",
    },
    "surnamePlaceholder": {
      "hr": "npr. Horvat",
      "en": "e.g. Doe",
    },
    "swift": {
      "hr": "SWIFT",
      "en": "SWIFT",
    },
    "swiftPlaceholder": {
      "hr": "npr. ZABAHR",
      "en": "e.g. ZABAHR",
    },
    "switchOrganization": {
      "hr": "Promjenite organizaciju",
      "en": "Switch organization",
    },
    "termsOfUse": {
      "hr": "Uvjeti korištenja",
      "en": "Terms of Use",
    },
    "termsOfUseContent": {
      "hr": "<p>CODICODE d.o.o. izdavač je internet stranice i web aplikacije. Korištenjem internetske stranice smatra se da ste kao korisnik u potpunosti upoznati i suglasni s navedenim Uvjetima korištenja i Uvjetima prodaje.</p>\n" +
        "    <p>Ukoliko se s istima ne slažete i ne prihvaćate ih, molimo vas da se ne koristite ovom internetskom stranicom. Svakim daljnjim korištenjem ovih Internetskih stranica nakon upoznavanja s Uvjetima korištenja, smatra se da su korisnici suglasni s ovdje iznesenim uvjetima i pravilima te pristaju na korištenje internetskih stranica sukladno tim uvjetima. Korisnik je suglasan da je korištenje internetskih stranica isključivo na njegovu odgovornost. CODICODE d.o.o. ne može ni na koji način jamčiti da korištenje ove internetske stranice neće biti prekinuto ili bez pogrešaka, niti mogu jamčiti za posljedice korištenja ove internetske stranice ili točnost, pouzdanost ili sadržaj bilo koje informacije, usluge ili robe dane preko ovih internetskih stranica.Ukoliko se s istima ne slažete i ne prihvaćate ih, molimo vas da se ne koristite ovom internetskom stranicom. Svakim daljnjim korištenjem ovih Internetskih stranica nakon upoznavanja s Uvjetima korištenja, smatra se da su korisnici suglasni s ovdje iznesenim uvjetima i pravilima te pristaju na korištenje internetskih stranica sukladno tim uvjetima. Korisnik je suglasan da je korištenje internetskih stranica isključivo na njegovu odgovornost. CODICODE d.o.o. ne može ni na koji način jamčiti da korištenje ove internetske stranice neće biti prekinuto ili bez pogrešaka, niti mogu jamčiti za posljedice korištenja ove internetske stranice ili točnost, pouzdanost ili sadržaj bilo koje informacije, usluge ili robe dane preko ovih internetskih stranica.</p>\n" +
        "    <p>Korisnik se slaže i potvrđuje da CODICODE d.o.o. nije odgovoran za neprikladno ili protuzakonito ponašanje drugih korisnika ili trećih strana te da je rizik od eventualnih šteta nastalih kao rezultat navedenog u potpunosti na korisniku. Izjava o odgovornosti odnosi se na sve štete uzrokovane bilo kakvom greškom, brisanjem, prekidom, računalnim virusom, kvarom, kašnjenjem u radu ili prijenosu, prekidom u komunikacijskoj liniji, krađom, raskidom ugovora, uništenjem ili neovlaštenim pristupom, promjenama ili zlouporabom zapisa, neprikladnim ponašanjem, nemarom, ili bilo kakvom drugom radnjom.</p>\n" +
        "    <p>CODICODE d.o.o. ili druga fizička ili pravna osoba uključena u stvaranje, proizvodnju i distribuciju ove intenetske stranice nisu odgovorni ni za kakvu štetu nastalu kao posljedica korištenja ili nemogućnosti korištenja ovih internetskih stranica.</p>\n" +
        "    <p>Bilo kakva oštećenja opreme korisnika nastala kao posljedica korištenja ovih internetskih stranica nisu u odgovornosti CODICODE d.o.o.. CODICODE d.o.o. zadržava pravo u svakom trenutku, i bez prethodne obavijesti, ukoliko to smatra potrebnim, ukinuti ili izmijeniti bilo koji od ovdje navedenih Uvjeta korištenja internetske stranice ili Uvijeta prodaje. Predmetne izmjene mogu između ostalog uključivati i uvođenje određenih naknada ili naplata, pa savjetujemo korisnike da periodično ponovno detaljno pročitaju informacije sadržane u Uvjetima korištenja i Uvjetima prodaje, a kako bi bili informirani o eventualnim promjenama.</p>\n" +
        "    <p>Također, CODICODE d.o.o. zadržava pravo u bilo kojem trenutku izmijeniti ili ukinuti bilo koji segment poslovanja, uključujući, i bez ograničenja, vrijeme dostupnosti, sadržaj, kao i opremu potrebnu za pristup ili korištenje internet stranica.</p>\n" +
        "    <p>Svaka eventualna izmjena ili brisanje uvjeta korištenja važeća je prema trećima odmah po objavi na internetskim stranicama. Svako korištenje, osim upoznavanja s izmjenama uvjeta, predmetnih internetskih stranica od strane krajnjeg korisnika nakon takve promjene podrazumijevat će se kao prihvaćanje tih promjena.</p>\n" +
        "    <p>Korisnici ne smiju putem ovih internetskih stranica objavljivati, prenositi ili učiniti dostupnima na bilo koji način nikakve materijale čijim sadržajem se na bilo koji način krše prava drugih, primjerice, sadržaj koji je uvredljiv, klevetnički, koji narušava ili ugrožava privatnost itd., kao ni materijale nezakonitog sadržaja i forme. CODICODE d.o.o. zadržava diskrecijsko pravo da sam ocijeni je li došlo do povrede obveza od strane korisnika. Korisnik ne smije bez prethodnog pismenog odobrenja CODICODE d.o.o. objavljivati, prenositi ili učiniti dostupnima na bilo koji način sadržaj koji sadrži oglas, reklamu ili ponudu bilo čijih proizvoda i usluga, kao ni vjersku, političku i nekomercijalnu promidžbu, a posebno ne smije vrbovati druge korisnike internetskih stranica da postanu korisnici druge usluge koja je direktna ili indirektna konkurencija. Materijali koji se nalaze na internetskim stranicama u vlasništvu su CODICODE d.o.o. te su kao takvi zaštićeni zakonom, ili se koriste sukladno odobrenju nositelja autorskih prava te nositelja prava na žigovima, kao i sukladno drugim potrebnim odobrenjima. CODICODE d.o.o. je također nositelj autorskih prava na cjelokupnom idejnom sadržaju internetske stranice neophodnom za ispravno i nesmetano funkcioniranje.</p>\n" +
        "    <p>Ilustracije koje su objavljene na stranici autorska su dijela i vlasništvo CODICODE d.o.o. ili besplatne za upotrebu.</p>\n" +
        "    <p>Zabranjeno je bilo kakva kopiranje, prijenos, distribucija, povezivanje, objavljivanje ili na bilo koji način mijenjanje ovih internetskih stranica bez pismenog odobrenja CODICODE d.o.o.. Svako kršenje spomenute zabrane može imati za posljedicu povredu autorskih prava, prava žiga, ili kakvog drugog prava pa stoga može proizvesti neželjene učinke kao što su pokretanja više vrsta sudskih postupaka, a osobito može dovesti do kaznenog progona. Korisnik ne smije mijenjati, objavljivati, prenositi, sudjelovati u prijenosu ili prodaji, stvarati izvedenice, ili na bilo koji drugi način iskorištavati bilo koji sadržaj sa internetske stranice u cijelosti ili djelomično.</p>\n" +
        "    <p>Također nije dozvoljeno kopiranje, redistribucija, ponovni prijenos, izdavanje ili komercijalno iskorištavanje preuzetog materijala. Korisnik se slaže da neće smatrati odgovornim i da će obeštetiti CODICODE d.o.o. od svih zahtjeva za naknadom štete i troškova.</p>\n" +
        "    <p>CODICODE d.o.o. nije odgovoran za bilo kakav eventualni gubitak ili štetu nastalu iz razloga što se korisnik oslonio na informacije dobivene na internetskim stranicama. CODICODE d.o.o. se izričito ograđuje u svakom smislu od sadržaja materijala na internet stranicama trećih strana, a na koje bi se moglo pristupiti sa internetske stranice. U slučaju da je neka od stavki ovih uvjeta korištenja ništava, takva ništavost neće utjecati na valjanost bilo koje druge odredbe ovih uvjeta, te će preostali dijelovi ovih Uvjeta ostati na snazi, a ništavna odredba će se zamijeniti valjanom odredbom koja po smislu i pravoj volji najviše odgovara odredbi koja je ništavna. Ukoliko bilo koja strana ne iskoristi svoja prava iz ovih uvjeta korištenja, to se neće smatrati odricanjem ili gubitkom tih prava, kao ni bilo kojih drugih ovdje navedenih prava.</p>\n" +
        "    <p>Osobama mlađim od 14. godina zabranjeno je korištenje internetske stranice. Bilo koji spor ili zahtjev, proizašao iz, ili koji je u vezi s ovim Uvjetima, ili u vezi s njihovim provođenjem, u nadležnosti je suda u Zagrebu i rješava se u skladu s propisima Republike Hrvatske.</p>\n" +
        "    <p>Posjetom stranici smatra se da je korisnik ove Uvjete korištenja u cijelosti pročitao.</p>",
      "en": "<p>CODICODE d.o.o. is a publisher of websites and web applications. By using the website, it is considered that you, as a user, are fully familiar with and agree to the stated Terms of Use and Terms of Sale.</p>\n" +
        "    <p>If you do not agree with them and do not accept them, please do not use this website. With any further use of these Internet pages after familiarization with the Terms of Use, it is considered that the users agree with the conditions and rules presented here and agree to use the Internet pages in accordance with these conditions. The user agrees that the use of the Internet pages is solely his responsibility. CODICODE d.o.o. cannot in any way guarantee that the use of this website will not be interrupted or error-free, nor can they guarantee the consequences of using this website or the accuracy, reliability or content of any information, services or goods provided through these websites. If you do not agree and do not accept them, please do not use this website. With any further use of these Internet pages after familiarization with the Terms of Use, it is considered that the users agree with the conditions and rules presented here and agree to use the Internet pages in accordance with these conditions. The user agrees that the use of the Internet pages is solely his responsibility. CODICODE d.o.o. cannot in any way guarantee that the use of this website will be uninterrupted or error-free, nor can it guarantee the consequences of using this website or the accuracy, reliability or content of any information, services or goods provided through these websites.</p>\n" +
        "    <p>The user agrees and confirms that CODICODE d.o.o. is not responsible for the inappropriate or illegal behavior of other users or third parties and that the risk of possible damages arising as a result of the above is entirely on the user. The liability statement applies to all damages caused by any error, deletion, interruption, computer virus, malfunction, delay in operation or transmission, interruption in the communication line, theft, termination of contract, destruction or unauthorized access, alteration or misuse of records, inappropriate behavior , negligence, or any other act.</p>\n" +
        "    <p>CODICODE d.o.o. or other natural or legal person involved in the creation, production and distribution of this website are not responsible for any damage resulting from the use or inability to use these websites.</p>\n" +
        "    <p>CODICODE d.o.o. is not responsible for any damage to the user's equipment as a result of using these websites. CODICODE d.o.o. reserves the right at any time, and without prior notice, if it deems it necessary, to cancel or modify any of the Terms of Use of the Website or the Terms of Sale stated here. The changes in question may include, among other things, the introduction of certain fees or charges, so we advise users to periodically re-read the information contained in the Terms of Use and the Terms of Sale in detail, in order to be informed of any changes.</p>\n" +
        "    <p>Also, CODICODE d.o.o. reserves the right at any time to modify or terminate any segment of the business, including, without limitation, availability time, content, as well as the equipment required to access or use the website.</p>\n" +
        "    <p>Any possible modification or deletion of the terms of use is valid for third parties immediately after publication on the website. Any use, apart from familiarization with changes to the conditions, of the relevant internet pages by the end user after such changes will be understood as acceptance of those changes.</p>\n" +
        "    <p>Users may not publish, transfer or make available in any way through these websites any materials whose content infringes the rights of others in any way, for example, content that is offensive, defamatory, that violates or threatens privacy, etc., as well as materials illegal content and form. CODICODE d.o.o. reserves the discretionary right to assess whether there has been a breach of obligations by the user. The user may not without the prior written approval of CODICODE d.o.o. publish, transmit or make available in any way content that contains an advertisement, advertisement or offer of any products and services, as well as religious, political and non-commercial promotion, and in particular may not recruit other users of the Internet pages to become users of another service that is direct or indirect competition. The materials on the website are owned by CODICODE d.o.o. and as such are protected by law, or are used in accordance with the approval of the copyright holder and trademark rights holder, as well as in accordance with other necessary approvals. CODICODE d.o.o. is also the copyright holder on the entire conceptual content of the website necessary for its correct and smooth functioning.</p>\n" +
        "    <p>The illustrations published on the page are the copyrighted part and the property of CODICODE d.o.o. or free to use.</p>\n" +
        "    <p>It is forbidden to copy, transfer, distribute, connect, publish or in any way modify these internet pages without the written permission of CODICODE d.o.o.. Any violation of the aforementioned prohibition may result in a violation of copyright, trademark rights, or any other right and therefore may produce unwanted effects such as the initiation of several types of court proceedings, and in particular may lead to criminal prosecution. The user may not change, publish, transfer, participate in the transfer or sale, create derivatives, or in any other way exploit any content from the website in whole or in part.</p>\n" +
        "    <p>It is also not allowed to copy, redistribute, retransmit, publish or commercially exploit the downloaded material. The user agrees not to hold CODICODE d.o.o. liable and to indemnify CODICODE d.o.o. from all claims for damages and costs.</p>\n" +
        "    <p>CODICODE d.o.o. is not responsible for any possible loss or damage caused by the user's reliance on information obtained on the Internet. CODICODE d.o.o. is expressly excluded in every sense from the content of material on the websites of third parties, which could be accessed from the website. In the event that one of the clauses of these terms of use is invalid, such invalidity will not affect the validity of any other provision of these terms, and the remaining parts of these Terms will remain in force, and the invalid provision will be replaced by a valid provision that, by meaning and true intention, is the most corresponds to a provision that is void. The failure of either party to exercise its rights under these terms of use shall not be deemed a waiver or loss of those rights, or any other rights set forth herein.</p>\n" +
        "    <p>Persons under the age of 14 are prohibited from using the website. Any dispute or claim, arising from or related to these Terms, or in connection with their implementation, is under the jurisdiction of the court in Zagreb and is resolved in accordance with the regulations of the Republic of Croatia.</p>\n" +
        "    <p>By visiting the page, the user is considered to have read these Terms of Use in their entirety.</p>",
    },
    "title": {
      "hr": "Naziv",
      "en": "Title",
    },
    "titlePlaceholderArticle": {
      "hr": "npr. Borer",
      "en": "e.g. Borer",
    },
    "titlePlaceholderAmount": {
      "hr": "npr. 100,00",
      "en": "e.g. 100,00",
    },
    "titlePlaceholderAmountWithVAT": {
      "hr": "npr. 100,00",
      "en": "e.g. 100,00",
    },
    "titlePlaceholderAmountWithoutVAT": {
      "hr": "npr. 100,00",
      "en": "e.g. 100,00",
    },
    "titlePlaceholderBank": {
      "hr": "npr. Zagrebačka banka d.d.",
      "en": "e.g. Unicredit",
    },
    "titlePlaceholderCurrentBalance": {
      "hr": "npr. 1000",
      "en": "e.g. 1000",
    },
    "titlePlaceholderDate": {
      "hr": "npr. 16.01.2023.",
      "en": "e.g. 16.01.2023.",
    },
    "titlePlaceholderDateOfExchangeRate": {
      "hr": "npr. 16.01.2023.",
      "en": "e.g. 16.01.2023.",
    },
    "titlePlaceholderPlace": {
      "hr": "npr. Zagreb",
      "en": "e.g. London",
    },
    "titlePlaceholderPlaceOfCosts": {
      "hr": "npr. Zagreb",
      "en": "e.g. London",
    },
    "titlePlaceholderCounty": {
      "hr": "npr. Zagrebačka župaija",
      "en": "e.g. Zagreb county",
    },
    "titlePlaceholderNumberOfAccount": {
      "hr": "npr. 123456-3226667777",
      "en": "e.g. 123456-3226667777",
    },
    "titlePlaceholderInitialBalance": {
      "hr": "npr. 1000",
      "en": "e.g. 1000",
    },
    "titlePlaceholderInvoiceNumber": {
      "hr": "npr. 1/1/1",
      "en": "e.g. 1/1/1",
    },
    "titlePlaceholderInvoiceNumberInYear": {
      "hr": "npr. 1",
      "en": "e.g. 1",
    },
    "titlePlaceholderInvoiceType": {
      "hr": "npr. R1",
      "en": "e.g. R1",
    },
    "titlePlaceholderModel": {
      "hr": "npr. HR99",
      "en": "e.g. HR99",
    },
    "titlePlaceholderPartner": {
      "hr": "npr. CODICODE d.o.o.",
      "en": "e.g. CODICODE d.o.o.",
    },
    "titlePlaceholderPaymentDescription": {
      "hr": "npr. Izrada kolosijeka",
      "en": "e.g. Track making",
    },
    "titlePlaceholderPaymentType": {
      "hr": "npr. D",
      "en": "e.g. D",
    },
    "titlePlaceholderReferenceNumber": {
      "hr": "npr. 112233",
      "en": "e.g. 112233",
    },
    "titlePlaceholderOrganization": {
      "hr": "npr. Racuncheck",
      "en": "e.g. Racuncheck",
    },
    "titlePlaceholderUnitOfMeasure": {
      "hr": "npr. kg",
      "en": "e.g. kg",
    },
    "titlePlaceholderUnitAmount": {
      "hr": "npr. 1",
      "en": "e.g. 1",
    },
    "titlePlaceholderUnitDiscount": {
      "hr": "npr. 1",
      "en": "e.g. 1",
    },
    "titlePlaceholderUnitPrice": {
      "hr": "npr. 1",
      "en": "e.g. 1",
    },
    "titlePlaceholderYear": {
      "hr": "npr. 2022",
      "en": "e.g. 2022",
    },
    "totalDebt": {
      "hr": "Ukupni dugovni proment",
      "en": "Total Debt",
    },
    "totalDemand": {
      "hr": "Ukupni potražni proment",
      "en": "Total Demand",
    },
    "transaction": {
      "hr": "Transakcija",
      "en": "Transaction",
    },
    "transactions": {
      "hr": "Transakcije",
      "en": "Transactions",
    },
    "transactionHasBeenUsed": {
      "hr": "Transakcija je iskorištena",
      "en": "Transaction has been used",
    },
    "type": {
      "hr": "Tip",
      "en": "Type",
    },
    "undefined": {
      "hr": "N/A",
      "en": "N/A",
    },
    "unitAmount": {
      "hr": "Količina",
      "en": "Unit Amount",
    },
    "unitDiscount": {
      "hr": "Popust",
      "en": "Unit Discount",
    },
    "unitPrice": {
      "hr": "Jed. cijena",
      "en": "Unit Price",
    },
    "unitOfMeasure": {
      "hr": "Jedinica količine",
      "en": "Unit of Measure",
    },
    "unitOfMeasures": {
      "hr": "Jedinice količine",
      "en": "Unit of Measures",
    },
    "userIsAlreadyInOrganization": {
      "hr": "Korisnik je već u organizaciji",
      "en": "User is already in organization",
    },
    "vatNumber": {
      "hr": "Porezni broj",
      "en": "VAT Number",
    },
    "vatNumberPlaceholder": {
      "hr": "npr. 112233445566",
      "en": "e.g. 112233445566",
    },
    "verification": {
      "hr": "Verifikacija",
      "en": "Verification",
    },
    "verificationToken": {
      "hr": "Verifikacijski token",
      "en": "Verifikacijski token",
    },
    "verificationTokenPlaceholder": {
      "hr": "npr. 6d717498653e7bd5ab4fa300051b7816",
      "en": "e.g. 6d717498653e7bd5ab4fa300051b7816",
    },
    "year": {
      "hr": "Godina",
      "en": "Year",
    },
    "yearPlaceholder": {
      "hr": "npr 2023",
      "en": "e.g. 2023",
    },
    "yes": {
      "hr": "Da",
      "en": "Yes",
    },
    "yesIAm": {
      "hr": "Da, jesam",
      "en": "Yes, I am",
    },
    "yourOrganizations": {
      "hr": "Vaše organizacije",
      "en": "Your organizations",
    },
    "weRunSoYouCanManage": {
      "hr": "Mi pokrećemo kako bi vi upravljali",
      "en": "We run so you can manage",
    },
  },
];
export default translations;