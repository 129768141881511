// Models
import {AppUserIndex} from "@/models/app/user";

export interface AppOrganizationMemberIndex {
  id: string;
  user_id: string | string[];
  organization_id: string | string[];
  user: AppUserIndex;
  role: string;
  is_selected: boolean;
}

export class AppOrganizationMemberIndex implements AppOrganizationMemberIndex {
  id: string;
  user_id: string | string[];
  organization_id: string | string[];
  user: AppUserIndex;
  role: string;
  is_selected: boolean;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.user = new AppUserIndex();
    this.role = "";
    this.is_selected = false;
  }
}