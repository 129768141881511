import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

// Components
import HomeIndex from "../views/home/index.vue";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getOrganizationMember.role) {
    case "administrator":
      next({ name: "AppAdministratorDashboardRootIndexVue" });
      break;
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.isAuthenticated) {
    next();
  } else if (to.meta.protectedRoute && !store.getters.isAuthenticated) {
    next({ name: "HomeIndexVue" });
  } else if (!to.meta.protectedRoute && store.getters.isAuthenticated) {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: HomeIndex,
    beforeEnter: routerGuard,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUseIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "terms-of-use" */ "../views/terms-of-use/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/privacy-policy/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "password-recovery" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:verification_token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "password-recovery-change-password" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/organization-wizard",
    name: "AppOrganizationWizardIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-organization-wizard" */ "../views/app/organization-wizard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/organizations",
    name: "AppAdministratorOrganizationsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-organizations-root" */ "../views/app/administrator/organizations/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/organizations/details/:id",
    name: "AppAdministratorOrganizationsDetailsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-organizations-details-root" */ "../views/app/administrator/organizations/details/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/organizations/details/:id/organization-members",
    name: "AppAdministratorOrganizationsDetailsOrganizationMembersIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-organizations-details-organization-members" */ "../views/app/administrator/organizations/details/organization-members/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/dashboard",
    name: "AppAdministratorDashboardRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-dashboard-root" */ "../views/app/administrator/dashboard/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data",
    name: "AppAdministratorMasterDataRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-root" */ "../views/app/administrator/master-data/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/banks",
    name: "AppAdministratorMasterDataBanksIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-banks" */ "../views/app/administrator/master-data/banks/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/partners",
    name: "AppAdministratorMasterDataPartnersRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-partners-root" */ "../views/app/administrator/master-data/partners/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/partners/:id/details",
    name: "AppAdministratorMasterDataPartnersDetailsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-partners-details" */ "../views/app/administrator/master-data/partners/details/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/place-of-costs",
    name: "AppAdministratorMasterDataPlaceOfCostsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-place-of-costs" */ "../views/app/administrator/master-data/place-of-costs/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/articles",
    name: "AppAdministratorMasterDataArticlesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-articles" */ "../views/app/administrator/master-data/articles/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/counties",
    name: "AppAdministratorMasterDataCountiesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-counties" */ "../views/app/administrator/master-data/counties/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/places",
    name: "AppAdministratorMasterDataPlacesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-places" */ "../views/app/administrator/master-data/places/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/unit-of-measures",
    name: "AppAdministratorMasterDataUnitOfMeasuresIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-unit-of-measures" */ "../views/app/administrator/master-data/unit-of-measures/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/payment-types",
    name: "AppAdministratorMasterDataPaymentTypesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-payment-types" */ "../views/app/administrator/master-data/payment-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/master-data/invoice-types",
    name: "AppAdministratorMasterDataInvoiceTypesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-master-data-invoice-types" */ "../views/app/administrator/master-data/invoice-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices",
    name: "AppAdministratorInvoicesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-root" */ "../views/app/administrator/invoices/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/outgoing-invoices",
    name: "AppAdministratorInvoicesOutgoingInvoicesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-outgoing-invoices" */ "../views/app/administrator/invoices/outgoing-invoices/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/incoming-invoices",
    name: "AppAdministratorInvoicesIncomingInvoicesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-incoming-invoices" */ "../views/app/administrator/invoices/incoming-invoices/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/excerpts",
    name: "AppAdministratorInvoicesExcerptsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-excerpts-root" */ "../views/app/administrator/invoices/excerpts/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/excerpts/:id/details",
    name: "AppAdministratorInvoicesExcerptsDetailsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-excerpts-details" */ "../views/app/administrator/invoices/excerpts/details/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/invoice-payments",
    name: "AppAdministratorInvoicesInvoicePaymentsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-invoice-payments" */ "../views/app/administrator/invoices/invoice-payments/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/invoices/compensations",
    name: "AppAdministratorInvoicesCompensationsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-invoices-compensations" */ "../views/app/administrator/invoices/compensations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/reports",
    name: "AppAdministratorReportsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-reports-root" */ "../views/app/administrator/reports/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/reports/incoming-invoices",
    name: "AppAdministratorReportsIncomingInvoicesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-reports-incoming-invoices" */ "../views/app/administrator/reports/incoming-invoices/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/reports/outgoing-invoices",
    name: "AppAdministratorReportsOutgoingInvoicesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-reports-outgoing-invoices" */ "../views/app/administrator/reports/outgoing-invoices/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/reports/place-of-costs",
    name: "AppAdministratorReportsPlaceOfCostsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-reports-place-of-costs" */ "../views/app/administrator/reports/place-of-costs/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
});

export default router;
